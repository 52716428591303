.intro {
	position: absolute;
	z-index: 1000;
	height: 100%;
	width: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	background-color: black;
	background-position: center;
	background-size: cover;
	background-image: url("../../style/assets/intro/background.jpg");

	font-family: "OswaldBold";
	color: white;
	text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.6);
	font-weight: 600;
	letter-spacing: 0.1rem;
	font-size: 1.2em;

	transition: opacity 1s;
	margin: 0;
}

.intro-header {
	/* position: fixed; */
	width: 100%;
	height: 20%;
	z-index: 10;

	top: 0;
	left: 0;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	/* background-position: bottom;
	background-repeat: repeat-x;
	background-size: contain;
	background-image: url("../../style/assets/intro/fadeTop2.png");*/

	pointer-events: none;
}

.intro-header-logo {
	aspect-ratio: 1/1;
	height: 80%;

	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	background-image: url("../../style/assets/intro/logoPresidence.png");
}

.intro-overflowedContainer {
	position: relative;
	width: 100%;
	height: 80%;

	overflow: hidden;
}

.intro-overflowed {
	position: relative;
	width: 100%;
	height: 100%;

	overflow-x: hidden;
	overflow-y: auto;

	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.intro-overflowed::-webkit-scrollbar {
    display: none;
}

/* .intro-fade-top {
    position: absolute;
    aspect-ratio: 690/300;
	width: 100%;
    z-index: 10;

    top: 0;
    left: 0;

    transform: translateY(-4px);

    opacity: 0;

	background-position: top;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url("../../style/assets/intro/fadeTop.png");

    pointer-events: none;
} */

.intro-fade-bottom {
	position: absolute;
	aspect-ratio: 690/300;
	width: 100%;
	z-index: 10;

	bottom: 0;
	left: 0;

	transform: translateY(20%);

	background-position: bottom;
	background-repeat: repeat-x;
	background-size: contain;
	background-image: url("../../style/assets/intro/fadeBottom.png");

	transition: all 2s linear;

	pointer-events: none;
}

.intro-explanations {
	margin: min(4vw, 2vh) auto min(4vw, 3vh) auto;
	width: 80%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.intro-explanations-title {
	margin: 0 auto min(4vw, 2vh) auto;

	opacity: 0.9;

	font-family: "AiglonPro-Bold";
	font-size: max(1px, min(3.5vw, 2.5vh, 100vw));
	text-align: center;
	line-height: max(1px, min(6vw, 4vh, 100vw));
	letter-spacing: 0.05rem;
}

.intro-explanations-text {
	margin: 0 auto min(4vw, 2vh) auto;

	opacity: 0.9;

	font-family: "AiglonProNarrow-Light";
	font-size: max(1px, min(3vw, 2vh, 100vw));
	text-align: center;
	line-height: max(1px, min(4vw, 3vh, 100vw));
	letter-spacing: 0.1rem;
}

.intro-bloc {
	width: 90%;

	margin: 0 auto min(4vw, 4vh) auto;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	border-radius: 0 0 8px 8px;

	overflow: hidden;
}

.intro-bloc-header {
	width: 100%;

	margin: min(2vw, 1.5vh) auto min(2vw, 1.5vh) auto;

	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;

	font-family: "AiglonPro-Bold";
	font-size: max(1px, min(5vw, 3vh, 100vw));
	line-height: max(1px, min(8vw, 6vh, 100vw));
	letter-spacing: 0.05rem;
	text-align: center;
}

.intro-bloc-videoTrailer {
	position: relative;
	aspect-ratio: 1920/1080;
	width: 100%;

	margin: 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	border-radius: 8px 8px 0 0;
}

.intro-bloc-videoTrailer-playIcon {
	aspect-ratio: 1/1;
	height: 30%;

	margin: 0;

	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url("../../style/assets/intro/playIcon.png");

	filter: drop-shadow(0px 1px 2px #000000);
}

.intro-bloc-videoTrailer-newIcon {
	position: absolute;
	aspect-ratio: 1/1;
	height: 10%;

	margin: min(1vw, 1vh) 0 min(1vw, 1vh) 0;
	bottom: 0;
	left: 50%;

	transform: translateX(-50%);

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	border-radius: 4px;

	background-color: #196aff;
}

.intro-bloc-videoTrailer-newIconText {
	margin: 0 min(4vw, 3vh) 0 min(4vw, 3vh);
	opacity: 0.9;

	font-family: "AiglonPro-Bold";
	font-size: max(0px, min(2vw, 1.5vh, 100vw));
	text-align: center;
	line-height: max(0px, min(4vw, 3vh, 100vw));
	letter-spacing: 0.1rem;
	color: white;
}

.intro-bloc-infos {
	width: 100%;

	margin: 0;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	background-color: #262626;
}

.intro-bloc-infos-more {
	margin: min(2vw, 1vh) 0 min(2vw, 1vh) min(2vw, 1.5vh);

	opacity: 0.6;

	font-family: "AiglonProNarrow-Light";
	font-size: max(1px, min(3vw, 2vh, 100vw));
	text-align: center;
	line-height: max(1px, min(4vw, 3vh, 100vw));
	letter-spacing: 0.1rem;
}

.intro-bloc-infos-timer {
	margin: min(2vw, 1vh) min(2vw, 1.5vh) min(2vw, 1vh) 0;

	opacity: 0.9;

	font-family: "AiglonPro-Bold";
	font-size: max(1px, min(3vw, 2vh, 100vw));
	text-align: center;
	line-height: max(1px, min(4vw, 3vh, 100vw));
	letter-spacing: 0.05rem;
	letter-spacing: 0.1rem;
}

.intro-logos {
	width: 80%;

	margin: 0 auto min(4vw, 2vh) auto;

	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	gap: 4vh;
}

.intro-logos-logo {
	margin: min(6vw, 4vh) 0 min(12vw, 8vh) 0;
	aspect-ratio: 1/1;
	height: min(18vw, 10vh);

	background-position: center;
	background-repeat: no-repeat;
	background-size: auto 100%;
}

/* OLD */
.intro-video {
	position: absolute;
	width: 100%;
	height: 100%;
}

.intro-click {
	z-index: 12000;
	position: absolute;
	width: 80%;
	height: 30%;
}
