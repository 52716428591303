.landingPage {
    position: absolute;
	z-index: 1000;
	height: 100%;
	width: 100%;

	background-position: top;
	background-size: cover;
	background-repeat: no-repeat;

    background-blend-mode: darken;

    overflow-y: auto;
}

.landingPage-overflowed {
	position: relative;
	width: 100%;
	height: 100%;

    margin: 0 auto 0 auto;

	overflow-x: hidden;
	overflow-y: auto;

	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

.landingPage-overflowed::-webkit-scrollbar {
    display: none;
}

.landingPage-band {
	position: fixed;
	width: 100%;
	height: max(1px, min(20vw, 12vh, 100px));

	top: 0px;
	left: 0px;
}

.landingPage-band-cross {
	position: absolute;
	width: max(1px, min(8vw, 5vh, 32px));
	height: max(1px, min(8vw, 5vh, 32px));

	top: max(1px, min(8vw, 5vh, 32px));
	right: max(1px, min(8vw, 5vh, 32px));

	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	background-image: url(../../style/assets/icons/close.png);
}

.landingPage-title {
    width: 100%;
    height: max(1px, min(24vw, 16vh, 1000px));

    margin: max(1px, min(20vw, 8vh)) auto 0 auto;

    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 70%;
}

.landingPage-content {
    width: 75%;

    margin: max(1px, min(10vw, 8vh)) auto max(1px, min(10vw, 8vh)) auto;
    
    color: white;
}

.landingPage-content-title {
    margin: 0 auto max(1px, min(5vw, 5vh)) auto;

    text-align: center;

    font-family: "AiglonPro-Bold";
	font-size: max(1px, min(3.5vw, 2.5vh, 100vw));
	line-height: max(1px, min(6vw, 4vh, 100vw));
	letter-spacing: 0.05rem;
}

.landingPage-content-text {
    margin: 0 auto max(1px, min(3vw, 4vh)) auto;

    font-family: "AiglonProNarrow-Light";
	font-size: max(1px, min(3vw, 2vh, 100vw));
	text-align: center;
	line-height: max(1px, min(4vw, 3vh, 100vw));
	letter-spacing: 0.1rem;
}

.landingPage-bloc {
    width: 90%;

	margin: 0 auto min(4vw, 4vh) auto;

	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	border-radius: 0 0 8px 8px;

	overflow: hidden;

    color: white;
}

.link {
    color: white;
    text-decoration: none;

	font-family: "AiglonProWide-Light";
    font-size: max(1px, min(2.5vw, 2.9vh, 32px));
    letter-spacing: 0.05rem;
	font-weight: 700;

    cursor: pointer;

}

.landingPage-share {
	margin: 16px 0 max(1px, min(3vw, 1.4vh, 32px)) 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.landingPage-share-icon {
	aspect-ratio: 1/1;
	height: max(1px, min(4vw, 4vh, 32px));

	margin: 0 16px max(1px, min(2vw, 1.4vh, 16px)) 16px;

	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;
	background-image: url(../../style/assets/icons/share.png);
}

.landingPage-share-text {
	color: white;

	margin: 0 0 max(1px, min(2vw, 1.4vh, 16px)) 0;

	font-family: "AiglonProNarrow-Light";
	font-size: max(1px, min(2.8vw, 2.8vh, 32px));
	text-align: left;
	line-height: max(1px, min(3.7vw, 4vh, 32px));
	letter-spacing: 0.1rem;
}
