/* .credits {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

	background-color: black;

    font-family: 'OswaldBold';
    color: white;
    text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.6);
    font-weight: 600;
    letter-spacing: .1rem;
    font-size: 1.2em;

    transition: opacity 1s;
    margin: 0;
	padding: 10px;

	width: calc(100%-20px);
} */

.credits {
    position: absolute;
    z-index: 1000;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    background-color: black;

    font-family: 'OswaldBold';
    color: white;
    text-shadow: 0px 1px 5px rgba(0, 0, 0, 0.6);
    font-weight: 600;
    letter-spacing: .1rem;
    font-size: 1.2em;
    text-align: center;

    transition: opacity 1s;
    margin: 0;
}